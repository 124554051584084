<template>
  <div>
    <div v-if="$attrs.service">
      <el-table :data="$attrs.service">
        <el-table-column :label="$t('name')" prop="metadata.name" sortable min-width="150">
          <template slot-scope="scope">
            <div class="table-name-info">
              <svg-icon icon-class="Service"></svg-icon>
              <div class="content">
                <a @click="toDetail(scope.row.metadata.name)">
                  {{ scope.row.metadata.name }}
                </a>

                <i class="el-icon-document-copy" v-clipboard:copy="scope.row.metadata.name" style="margin-left: 6px;">
                </i>

                <span class="desc">
                  {{ moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('serviceType')">
          <template slot-scope="scope">
            {{ formatType(scope.row.spec) }}
          </template>
        </el-table-column>

        <el-table-column label="端口信息" width="350">
          <template slot-scope="scope">
            <div v-for="item in scope.row.spec.ports" :key="item.name">
              <el-popover ref="popover" placement="top" width="320">
                <img :src="portSVG" />

                <span slot="reference">
                  <a href="javascript:;">{{ $t("servicePort") }}:</a>
                </span>
              </el-popover>

              <span>
                {{ item.port ? item.port : "-" }}
                <i class="el-icon-document-copy" v-clipboard:copy="item.port"> </i>
              </span>

              <span> / </span>

              <el-popover ref="popover" placement="top" width="320">
                <img :src="nodeportSVG" />

                <span slot="reference">
                  <a href="javascript:;">{{ $t("nodePort") }}:</a>
                </span>
              </el-popover>

              <span>
                {{ item.nodePort ? item.nodePort : "-" }}
                <i class="el-icon-document-copy" v-clipboard:copy="item.nodePort"> </i>
              </span>

              <span> / </span>

              <el-popover ref="popover" placement="top" width="320">
                <img :src="containerPortSVG" />

                <span slot="reference">
                  <a href="javascript:;">{{ $t("targetPort") }}:</a>
                </span>
              </el-popover>

              <span>
                {{ item.targetPort ? item.targetPort : "-" }}
                <i class="el-icon-document-copy" v-clipboard:copy="item.targetPort"> </i>
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('clusterIP')" prop="spec.clusterIP"></el-table-column>

        <el-table-column :label="$t('LoadBalancer')">
          <template slot-scope="scope">
            <a
              :href="scope.row.status.loadBalancer.ingress[0].hostname"
              v-if="scope.row.spec.type === 'LoadBalancer' && scope.row.status.loadBalancer.ingress"
              @click.prevent="toLocation(scope.row.status.loadBalancer.ingress[0].hostname)"
            >
              {{ scope.row.status.loadBalancer.ingress[0].hostname }}
            </a>

            <a
              :href="scope.row.metadata.annotations['ingress-host']"
              v-else-if="
                scope.row.spec.type === 'NodePort' &&
                  scope.row.metadata.annotations &&
                  scope.row.metadata.annotations['ingress-host']
              "
              @click.prevent="toLocation(scope.row.metadata.annotations['ingress-host'])"
            >
              {{ scope.row.metadata.annotations["ingress-host"] }}
            </a>

            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else class="empty-text">
      {{ $t("empty") }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

import containerPortSVG from "@/assets/port/container-port.svg";
import portSVG from "@/assets/port/port.svg";
import nodeportSVG from "@/assets/port/nodeport.svg";

export default {
  data() {
    return {
      serviceList: [],
      serviceTotal: 0,
      loading: false,
      containerPortSVG,
      portSVG,
      nodeportSVG
    };
  },

  methods: {
    moment,

    formatType(spec) {
      if ("clusterIP" in spec && spec.clusterIP == "None" && spec.type == "ClusterIP") {
        return "Headless";
      } else {
        return spec.type;
      }
    },

    toDetail(name) {
      this.$router.push({
        path: `/detail/Service/${name}`,
        query: { ...this.$route.query }
      });
    },

    toLocation(href) {
      window.open(`http://${href}`, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped></style>
