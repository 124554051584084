<template>
  <div class="monitor-wrapper">
    <el-tabs type="card" v-model="monitorType">
      <el-tab-pane label="默认监控面板" name="default">
        <default-monitor v-if="monitorType === 'default'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DefaultMonitor from "./DefaultMonitor";

export default {
  components: {
    DefaultMonitor
  },

  data() {
    return {
      monitorType: "default"
    };
  },

  methods: {}
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.monitor-wrapper {
  position: relative;

  .monitor-radio {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
