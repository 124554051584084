<template>
  <div>
    <div class="resource-title">状态</div>
    <div class="conditions-container">
      <div v-for="(item, index) in conditions" :key="item.type">
        <div class="title" :class="{ primary: item.status === 'True', warning: item.status !== 'True' }">
          {{ index + 1 }}. {{ item.type }}
        </div>

        <div class="name-value">
          <span class="name">{{ $t("time") }}: </span>
          <span class="value">{{ moment(item.lastUpdateTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </div>

        <div class="name-value">
          <span class="name">{{ $t("status") }}: </span>
          <span class="value">{{ item.status }}</span>
        </div>

        <div class="name-value">
          <span class="name">{{ $t("message") }}: </span>
          <span class="value">
            <i class="el-icon-document-copy" v-clipboard:copy="item.message"></i>
            {{ item.message }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapConditions } from "utils/info.js";

export default {
  props: {
    info: {
      type: Object
    }
  },

  data() {
    return {
      conditions: []
    };
  },

  mounted() {
    this.conditions = mapConditions(this.type, this.info);
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style></style>
