<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('pod')">
          <pod
            type="application"
            :vendor="vendor"
            :region="region"
            :cluster="cluster"
            :namespace="namespace"
            :name="application"
          />
        </el-tab-pane>
        <el-tab-pane :label="$t('Service')">
          <service :service="$attrs.data.spec.services" />
        </el-tab-pane>

        <el-tab-pane :label="$t('ConfigMap')">
          <config-map :data="$attrs.data.spec.configMaps" type="ConfigMap" />
        </el-tab-pane>

        <el-tab-pane :label="$t('Secret')">
          <config-map :data="$attrs.data.spec.secretMaps" type="Secret" />
        </el-tab-pane>

        <el-tab-pane :label="$t('PersistentVolumeClaims')">
          <pvc :pvc="$attrs.data.spec.persistentVolumeClaims" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data.spec[typeMin]" />
    </el-tab-pane>

    <el-tab-pane :label="$t('monitor')" lazy>
      <monitor-wrapper />
    </el-tab-pane>

    <!-- <el-tab-pane :label="$t('applicationTopo')" lazy>
      <application-topo :data="$attrs.data" style="margin-bottom: 20px;" />
    </el-tab-pane> -->

    <!-- <el-tab-pane :label="$t('alarmRules')">
      <alarm />
    </el-tab-pane>

    <el-tab-pane>
      <div slot="label">
        <el-badge class="alert-dot" is-dot v-if="alertMessageDot">{{ $t("alarmMessages") }}</el-badge>
        <span v-else>{{ $t("alarmMessages") }}</span>
      </div>
      <alarm-message @dot="alertMessageDot = true" />
    </el-tab-pane> -->

    <el-tab-pane :label="$t('Event')">
      <event type="application" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Pod from "@/pages/Detail/components/Pod";
import Service from "@/pages/Detail/components/Service";
import ConfigMap from "@/pages/Detail/components/ConfigMap";
import Pvc from "@/pages/Detail/components/Pvc";
import Event from "@/pages/Detail/components/Event";

// import ApplicationTopo from "@/pages/Detail/components/ApplicationTopo";
// import ApplicationMetrics from "@/pages/Detail/components/ApplicationMetrics";
// import Alarm from "@/pages/Detail/components/Alarm";
// import AlarmMessage from "@/pages/Detail/components/AlarmMessage";
import MonitorWrapper from "@/pages/Detail/components/MonitorWrapper";

export default {
  components: {
    Pod,
    Service,
    ConfigMap,
    Pvc,
    Event,

    // ApplicationTopo,
    // ApplicationMetrics,
    // Alarm,
    // AlarmMessage,

    MonitorWrapper
  },

  data() {
    return {
      alertMessageDot: false
    };
  },

  computed: {
    type() {
      return this.$route.params.type;
    },
    
    typeMin() {
      return this.$route.params.type.toLowerCase();
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    application() {
      return this.$route.params.name;
    }
  }
};
</script>
