<template>
  <div>
    <div v-if="data">
      <div class="list-title small">概要</div>

      <div v-for="item in data" :key="item.container" class="container-conf">
        <div class="title">[{{ item.container }}]容器绑定如下配置：</div>
        <div v-for="(conf, index) in item.configs" :key="index" class="conf-name">
          <a @click="toDetail(conf.name)">
            {{ conf.name }}
          </a>
        </div>
      </div>

      <div class="list-title small">YAML</div>

      <editor v-model="yamlText" @init="editorInit" lang="yaml" theme="cobalt" width="100%" height="500"></editor>
    </div>

    <div v-else class="empty-text">
      {{ $t("empty") }}
    </div>
  </div>
</template>

<script>
const YAML = require("json2yaml");

export default {
  props: {
    data: Object,
    type: String
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  computed: {
    yamlText: {
      get() {
        return YAML.stringify(this.data);
      },
      set() {}
    }
  },

  methods: {
    editorInit(editor) {
      editor.setReadOnly(true);
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    toDetail(name) {
      this.$router.push({
        path: `/detail/${this.type}/${name}`,
        query: { ...this.$route.query }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.container-conf {
  margin-bottom: 10px;
}

.conf-name {
  text-indent: 20px;
  a {
    font-size: 12px;
  }
}
</style>
